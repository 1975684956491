// Function to format date in a specific locale
export function formatDate(date: string, locale?: string) {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const dateObj = new Date(date);
  const formatter = new Intl.DateTimeFormat(locale ?? "en-US", options);

  // Get the formatted parts
  const parts = formatter.formatToParts(dateObj);

  // Rearrange the parts in the desired order
  const day = parts.find(p => p.type === "day")?.value;
  const month = parts.find(p => p.type === "month")?.value;
  const year = parts.find(p => p.type === "year")?.value;

  // Return the custom formatted string
  return `${day} ${month} ${year}`;
}
